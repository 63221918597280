import { useTranslation } from "react-i18next"
import { Grid, Divider, Button, Stack } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { supportedLanguages } from "../constants"
import { useState } from "react"
import { getLoggedInRole } from "constant/helpersUtility"

const SidebarFooter = ({
    handleHistoryClick,
    handleNotificationsClick,
    handleChangeLanguage,
    handleHelp
}) => {
    const { t, i18n } = useTranslation()
    const [loggedInUserRole] = useState(() => getLoggedInRole())

    return (
        <Grid container pb={2}>
            <Divider
                variant="fullWidth"
                sx={{
                    borderColor: "gray.400",
                    mt: 2
                }}
            />
            <Grid xs={12} px={1}>
                {loggedInUserRole !== "INVESTOR" && (
                    <Grid container mb={1} spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Button
                                variant="contained"
                                color="brand"
                                fullWidth
                                onClick={handleHistoryClick}
                            >
                                {t("History")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                fullWidth
                                sx={{ color: "inherit" }}
                                variant="outlined"
                                onClick={handleNotificationsClick}
                            >
                                {t("Notifications")}
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {/** Language Selector */}
                <Stack direction="row" justifyContent="space-between">
                    <select
                        style={{
                            padding: 2,
                            borderRadius: "8px"
                        }}
                        onChange={(e) => handleChangeLanguage(e.target.value)}
                        defaultValue={i18n.language}
                    >
                        {supportedLanguages.map((lang) => {
                            return (
                                <option
                                    value={lang}
                                    sx={{
                                        cursor: "pointer",
                                        color: "#777"
                                    }}
                                >
                                    {lang}
                                </option>
                            )
                        })}
                    </select>
                    <HelpOutlineIcon
                        fontSize="medium"
                        sx={{
                            cursor: "pointer",
                            color: "#777",
                            "&:hover": { color: "#777" }
                        }}
                        onClick={() => handleHelp()}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default SidebarFooter
