import { Formik, Form } from "formik"
import { useCallback } from "react"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import FormWrapper from "../../components/FormWrapper"
import FormField from "../../components/FormField"
import { SelectWrapper, TextFieldWrapper } from "components/common/Ui/Form"
import FormAction from "../../components/FormAction"
import { goToNextPersonalDetails } from "redux/slices/selfOnboardSlice"
import { useSelfOnboardDropdown } from "../../hooks/useOnboardDropdown"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import {
    fetchCityByState,
    fetchStateByCountry,
    updateSelfOnboardProspect
} from "services/prospectsServices"
import { setCities, setStates } from "redux/slices/selfOnboardSlice"
import { REGEX } from "constant/globalConstants"
import { sortInAscendingOrder } from "constant/helpersUtility"

const title = "Personal details"
const MIN_PERSON_AGE = 18
const subtitle =
    "Please let us know what type of investor you are. This helps us tailor the onboarding experience to gather the right information based on your investor profile."

const getMaxDob = () => {
    const currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() - MIN_PERSON_AGE)
    return currentDate
}

const validationSchema = Yup.object({
    first_name: Yup.string().required("First name required"),
    last_name: Yup.string().required("Last name required"),
    dob: Yup.date()
        .max(getMaxDob(), `Minimum age ${MIN_PERSON_AGE} required`)
        .typeError("Valid date of birth required")
        .required("Date of birth required"),
    mobile_country_code: Yup.string().required("Required"),
    mobile: Yup.string()
        .required("Mobile number required")
        .matches(REGEX.mobileNumber, "Valid mobile number required"),
    address_1: Yup.string().required("Address Line 1 required"),
    address_2: Yup.string().required("Address Line 2 required"),
    country_id: Yup.string().required("Country required"),
    state_id: Yup.string().required("State required"),
    postal_code: Yup.string().matches(
        REGEX.postalCode,
        "Valid postal code required. Should be 1-8 digits"
    )
})

export default function PersonalDetails() {
    const { get } = useSelfOnboardApi()
    const { post, isLoading } = useSelfOnboardApi()
    useSelfOnboardDropdown()
    const dispatch = useDispatch()
    const {
        prospect_id,
        email,
        investorType,
        token,
        personalDetails,
        dropDowns: { countries, cities, states }
    } = useSelector((state) => state.selfOnboard)

    // eslint-disable-next-line
    const countryOptions = useCallback(
        countries.map(({ label, id }) => ({
            label,
            value: id
        })),
        [countries]
    )

    // eslint-disable-next-line
    const phoneCodeOptions = useCallback(
        countries.map(({ id, label, code }) => ({
            label: `${label} (${code})`,
            value: id
        })),
        [countries]
    )

    const handleNext = async (data) => {
        const { city_id, ...rest } = data
        let prospect_type = investorType.toLowerCase()
        prospect_type = prospect_type[0].toUpperCase() + prospect_type.slice(1)

        if (city_id.value) {
            rest["city_id"] = city_id.value
        } else {
            rest["city_text"] = city_id.label
        }
        const payload = {
            ...rest,
            prospect_type,
            prospect_id,
            email,
            token
        }

        const resp = await post({
            apiCaller: updateSelfOnboardProspect,
            payload
        })

        const defaultMsg = "Something went wrong. Try contacting support teams"
        if (resp.status !== 200) {
            const errMsg = resp?.data?.detail?.at(0)?.msg
            toast.error(errMsg || defaultMsg)
            return
        }

        const detail = resp?.data?.at(0)
        const status = resp?.data?.at(1)
        if (!!status && status !== 200) {
            toast.error(detail?.error || defaultMsg)
            return
        }

        dispatch(goToNextPersonalDetails(data))
    }

    const handleCountryChange = async (countryId) => {
        const [data, status] = await get({
            apiCaller: fetchStateByCountry,
            params: { country_id: countryId, token }
        })

        if (status !== 200) {
            toast.error("Failed to load state")
            return
        }

        const options = sortInAscendingOrder(
            data.values.map((state) => ({
                label: state.at(1),
                value: state.at(0)
            }))
        )
        dispatch(setStates(options))
    }

    const handleStateChange = async (stateId, setFieldValue) => {
        const [data, status] = await get({
            apiCaller: fetchCityByState,
            params: { state_id: stateId, token }
        })

        if (status !== 200) {
            toast.error("Failed to load cities")
            return
        }

        let options = sortInAscendingOrder(
            data.values.map((city) => ({
                label: city.at(1),
                value: city.at(0)
            }))
        )
        setFieldValue("city_id", null)
        dispatch(setCities(options))
    }

    const schemaList = [
        {
            name: "first_name",
            label: "First name",
            required: true
        },
        {
            name: "last_name",
            label: "Last name",
            required: true
        },
        {
            name: "mobile",
            type: "phone",
            label: "Mobile number",
            codeName: "mobile_country_code",
            codeLabel: "Country code",
            codeOptions: phoneCodeOptions || []
        },
        {
            name: "dob",
            label: "Date of birth",
            type: "date",
            required: true
        },
        {
            name: "address_1",
            label: "Address Line 1",
            required: true
        },
        {
            name: "address_2",
            label: "Address Line 2",
            required: true
        },
        {
            name: "country_id",
            label: "Country of Residence",
            options: countryOptions || [],
            type: "select",
            required: true,
            handleChange: handleCountryChange
        },
        {
            name: "state_id",
            label: "State",
            options: states,
            type: "select",
            required: true,
            handleChange: handleStateChange
        },

        {
            name: "city_id",
            label: "City",
            type: "creatable",
            options: cities,
            required: true
        },
        {
            name: "postal_code",
            label: "Postal code",
            required: true
        }
    ]

    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={personalDetails}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    {schemaList.map((schema) => {
                        return schema.type === "phone" ? (
                            <Box
                                key={schema.name}
                                sx={{ mb: 4, display: "flex" }}
                            >
                                <SelectWrapper
                                    size="normal"
                                    name={schema.codeName}
                                    label={schema.codeLabel}
                                    options={schema.codeOptions}
                                    fullWidth={false}
                                    required
                                    sx={{ width: "200px" }}
                                />
                                <TextFieldWrapper
                                    size="normal"
                                    name={schema.name}
                                    label={schema.label}
                                    required
                                />
                            </Box>
                        ) : (
                            <Box mb={4} key={schema.name}>
                                <FormField
                                    size="normal"
                                    name={schema.name}
                                    label={schema.label}
                                    type={schema.type}
                                    handleChange={schema.handleChange}
                                    options={schema.options}
                                    required={schema.required}
                                />
                            </Box>
                        )
                    })}
                    <FormAction isLoading={isLoading} />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
