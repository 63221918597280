/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import { Grid, Stack } from "@mui/material"
import { useDispatch } from "react-redux"
import { setchatBot } from "../../../redux/slices/navSlice"
import { setNotificationClick } from "../../../redux/slices/ChatGptSlice"
import "../layout.css"
import HistoryList from "../../modules/ChatGpt/HistoryList"
import SidebarLayout from "./SidebarLayout.js"

function ChatBotSidebar() {
    const locationPath = window.location.pathname

    const dispatch = useDispatch()
    useEffect(() => {
        if (
            !locationPath.includes("/chatgpt") &&
            !locationPath.includes("/kairosbot")
        ) {
            dispatch(setchatBot(false))
            dispatch(setNotificationClick(false))
        }

        // eslint-disable-next-line
    }, [locationPath])

    return (
        <>
            <Grid
                container
                sx={{
                    rowGap: 2,
                    overflowY: "none"
                }}
                width="100%"
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="space-between"
            >
                {/** TODO implement historylist within SidebarLayout */}
                {locationPath.includes("/chatgpt/history") ||
                locationPath.includes("/kairosbot/history") ? (
                    <Stack>
                        <HistoryList />
                    </Stack>
                ) : (
                    <SidebarLayout />
                )}
            </Grid>
        </>
    )
}

export default ChatBotSidebar
