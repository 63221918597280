import { Grid, Button } from "@mui/material"
import { getLoggedInRole } from "constant/helpersUtility"
import { useTranslation } from "react-i18next"
import { useState } from "react"

const FinancialFilterTabs = ({ selectedButton, handleButtonClick }) => {
    const [loggedInUserRole] = useState(() => getLoggedInRole())
    const { t } = useTranslation()
    const investorTab = {
        value: t("Stocks"),
        englishValue: "Stocks"
    }

    const otherTabs = [
        {
            value: t("Internal Data"),
            englishValue: "Internal Data"
        },
        {
            value: t("Portfolio"),
            englishValue: "Portfolio"
        },
        {
            value: t("Agent"),
            englishValue: "Agent"
        }
    ]
    const tabs =
        loggedInUserRole === "INVESTOR"
            ? [investorTab]
            : [investorTab, ...otherTabs]

    return (
        <Grid container spacing={2} px={1} mt={1}>
            {tabs.map((label, index) => (
                <Grid
                    key={index}
                    item
                    xs={12}
                    lg={loggedInUserRole === "INVESTOR" ? 12 : 6}
                >
                    <Button
                        key={index}
                        fullWidth={true}
                        color="brand"
                        onClick={() => handleButtonClick(label)}
                        variant={
                            selectedButton === label.value
                                ? "contained"
                                : "outlined"
                        }
                    >
                        {label.value}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )
}
export default FinancialFilterTabs
