import Accordion from "@mui/material/Accordion"
import { ConfirmDeleteDialog } from "components/common/Ui/Dialog"
import { Button } from "components/common/Ui/Form"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { IconButton, Typography, Box } from "@mui/material"
import { DeleteOutlined } from "@mui/icons-material"

import { ReactComponent as ExpandIcon } from "theme/images/AccordianExpandIcon.svg"
import AddForm from "./AddForm"
import { useDispatch, useSelector } from "react-redux"
import { deleteCrp4SelfOnboard, updateCrp4SelfOnboard } from "services"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { toast } from "react-toastify"
import {
    updatePartyDetails,
    deletePartyDetails
} from "redux/slices/selfOnboardSlice"
import { useState } from "react"
import Grid2 from "@mui/material/Unstable_Grid2"

const SUCCESS_MSG = "Successfully updated related party"
const FAILED_MSG =
    "Unable to update client-related party. Ensure all required fields are filled and try again."
const SUCCESS_DEL_MSG = "Success! Related party is deleted"
const FAILED_DEL_MSG = "Something went wrong! We couldn't delete related party."

const RelatedPartyDetails = ({
    name,
    type,
    email,
    address,
    country_id,
    nationality,
    is_pep,
    id
}) => {
    const [expandedAccordionId, setExpandedAccordionId] = useState(null)
    const { isLoading, update, doDelete } = useSelfOnboardApi()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [crpDeleteId, setCrpDeleteId] = useState(null)
    const { prospect_id } = useSelector((state) => state.selfOnboard)
    const dispatch = useDispatch()

    const handleUpdate = async (data) => {
        const payload = {
            ...data,
            useProspectId: true,
            clientId: prospect_id,
            countryOfResidence: data.country_id
        }

        const resp = await update({
            apiCaller: updateCrp4SelfOnboard,
            payload,
            id
        })

        if (resp.status === 500) {
            toast.error("Something went wrong. Try contacting support teams")
            return
        }

        if (resp?.data?.status) {
            dispatch(updatePartyDetails({ ...data, id }))
            toast.success(SUCCESS_MSG)
            setExpandedAccordionId(null)
        } else {
            toast.error(resp.data.errorMessage || FAILED_MSG)
        }
    }

    const handleDeleteClick = (crpId) => {
        setCrpDeleteId(crpId)
        setShowDeleteModal(true)
    }

    const handleDeleteConfirm = async () => {
        const resp = await doDelete({
            apiCaller: deleteCrp4SelfOnboard,
            id: crpDeleteId
        })

        if (resp?.data?.status) {
            toast.success(SUCCESS_DEL_MSG)
            dispatch(deletePartyDetails({ id: crpDeleteId }))
            setShowDeleteModal(false)
        } else {
            toast.error(resp?.data?.errorMessage || FAILED_DEL_MSG)
        }
    }

    return (
        <>
            <Accordion
                key={id}
                sx={{
                    "&:before": {
                        display: "none"
                    },
                    marginTop: 1,
                    px: 2,
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "#EBEBEB",
                    borderStyle: "solid",
                    boxShadow: "0px 2px 10px 0px #CDCDCD1A"
                }}
                expanded={expandedAccordionId === id}
                onChange={() => {
                    setExpandedAccordionId(
                        expandedAccordionId === id ? null : id
                    )
                }}
            >
                <AccordionSummary expandIcon={<ExpandIcon />}>
                    <Grid2
                        container
                        sx={{  width: "100%", alignItems: "center" }}
                    >
                        <Grid2 xs={3}>
                            <Typography variant="body1">{type}</Typography>
                        </Grid2>
                        <Grid2 xs={3}>
                            <Typography variant="body1">{name}</Typography>
                        </Grid2>
                        <Grid2 xs={4}>
                            <Typography variant="body1">{email}</Typography>
                        </Grid2>
                        <Grid2 xs={2} sx={{textAlign: "right", pr: 0.5}}>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleDeleteClick(id)
                                }}
                            >
                                <DeleteOutlined sx={{ color: "red.600" }} />
                            </IconButton>
                        </Grid2>
                    </Grid2>
                </AccordionSummary>
                <AccordionDetails>
                    <AddForm
                        handleSubmit={handleUpdate}
                        defaultValues={{
                            name,
                            type,
                            email,
                            address,
                            is_pep,
                            country_id,
                            nationality
                        }}
                    >
                        <FormAction isLoading={isLoading} />
                    </AddForm>
                </AccordionDetails>
            </Accordion>
            <ConfirmDeleteDialog
                isLoading={isLoading}
                handleCancelBtn={() => setShowDeleteModal(false)}
                showModal={showDeleteModal}
                handleDeleteBtn={handleDeleteConfirm}
            />
        </>
    )
}

const FormAction = ({ isLoading }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 1,
                justifyContent: "flex-end",
                alignItems: "center"
            }}
        >
            <Button variant="contained" type="submit" disabled={isLoading}>
                {isLoading ? "Saving..." : "Save"}
            </Button>
        </Box>
    )
}
export default RelatedPartyDetails
