export const reactRouterUtility = {
    navigate: null,
    location: null,
    forceUpdate: null
}

const ROLES = {
    RM: "ENTITY RM",
    HNI: ["CLIENT HEAD", "CLIENT FAMILY MEMBER"],
    INVESTOR: "INVESTOR"
}
const user = JSON.parse(localStorage.getItem("user"))

// will return RM | HNI || SUPERADMIN
export const getLoggedInRole = () => {
    const loggedInUserDesc = user?.userTypeId.description.toUpperCase()

    if (ROLES.RM === loggedInUserDesc) return "RM"
    else if (ROLES.INVESTOR === loggedInUserDesc) return "INVESTOR"
    else if (ROLES.HNI.includes(loggedInUserDesc)) return "HNI"

    return "SUPERADMIN"
}

export const getClientSlugBasedOnUserType = () => {
    const role = getLoggedInRole()
    switch (role) {
        case "HNI":
        case "INVESTOR":
            return "clientprofile"
        case "RM":
            return "myclients"
        default:
            return "clients"
    }
}

export const getLoggedInUserId = () => {
    const clientId = user?.userId
    return clientId
}

export const sortInAscendingOrder = (data) =>
    data.sort((a, b) =>
        a.label.toUpperCase() < b.label.toUpperCase()
            ? -1
            : a.label.toUpperCase() > b.label.toUpperCase()
              ? 1
              : 0
    )
