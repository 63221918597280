import { Drawer, IconButton, Tooltip } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import SidebarContent from "../ChatGptSidebar"
import logo from "theme/images/NewSidebarIcons/logoKairosV1.svg"
import DrawerHeader from "./DrawerHeader"
import { useNavigate } from "react-router-dom"

const GiaSidebar = ({ handleDrawerClose, mobileOpen, drawerWidth }) => {
    const navigate = useNavigate()
    return (
        <Drawer
            sx={{
                width: drawerWidth,

                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box"
                }
            }}
            variant="persistent"
            anchor="left"
            open={mobileOpen}
        >
            <DrawerHeader>
                <Tooltip title="Go to dashboard">
                    <IconButton
                        sx={{ width: 36, height: 36 }}
                        onClick={() => {
                            navigate("/dashboard")
                        }}
                    >
                        <img
                            style={{
                                width: "48px",
                                height: "48px",
                                objectFit: "cover"
                            }}
                            src={logo}
                            alt="logoIcon"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Close sidebar">
                    <IconButton
                        onClick={handleDrawerClose}
                        sx={{ width: 64, height: 64 }}
                    >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </DrawerHeader>
            <SidebarContent />
        </Drawer>
    )
}

export default GiaSidebar
