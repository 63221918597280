import { Add } from "@mui/icons-material"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { createCrp4Selfonboard } from "services"
import { toast } from "react-toastify"
import RelatedPartyWrapper from "./RelatedPartyWrapper"
import { Button } from "components/common/Ui/Form"
import { useState } from "react"
import AddForm from "./AddForm"
import { Box, Typography, Tooltip } from "@mui/material"
import RelatedPartyDetails from "./RelatedPartyDetails"
import { useDispatch, useSelector } from "react-redux"
import { goBack, goToNextRelatedParty } from "redux/slices/selfOnboardSlice"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"
import { savePartyDetails } from "redux/slices/selfOnboardSlice"

const title = "Related party details"
const subtitle =
    "Please add UBO, Shareholders owner >10% of entity, Signatories, Principal"
const SUCCESS_MSG = "Successfully added new related party for the client!"
const FAILED_MSG =
    "Unable to add client-related party. Ensure all required fields are filled and try again."

const defaultValues = {
    name: "",
    type: "",
    country_id: "",
    email: "",
    address: "",
    is_pep: "",
    nationality: ""
}

export default function RelatedParty() {
    const [showAddForm, setShowAddForm] = useState(false)
    const { isLoading, post } = useSelfOnboardApi()
    const dispatch = useDispatch()
    const { relatedPartyDetails, prospect_id, investorType } = useSelector(
        (state) => state.selfOnboard
    )

    const partySaved = relatedPartyDetails.length > 0

    const handleAddSubmit = async (data) => {
        let prospect_type = investorType.toLowerCase()
        prospect_type = prospect_type[0].toUpperCase() + prospect_type.slice(1)

        const payload = {
            ...data,
            prospect_type,
            useProspectId: true,
            clientId: prospect_id,
            countryOfResidence: data.country_id
        }

        const resp = await post({
            apiCaller: createCrp4Selfonboard,
            payload
        })

        if (resp.status === 500) {
            toast.error("Something went wrong. Try contacting support teams")
            return
        }

        if (resp?.data?.status) {
            toast.success(SUCCESS_MSG)
            dispatch(savePartyDetails({ ...data, id: resp.data.body.crpId }))
        } else {
            toast.error(resp.data.errorMessage || FAILED_MSG)
        }
    }

    return (
        <RelatedPartyWrapper title={title} subtitle={subtitle}>
            <Button
                startIcon={Add}
                variant="outlined"
                onClick={() => setShowAddForm(true)}
            >
                Add Related Party
            </Button>
            {showAddForm && (
                <Box
                    sx={{
                        mt: 4,
                        border: "1px solid",
                        borderColor: "gray.200",
                        p: 3,
                        borderRadius: "12px"
                    }}
                >
                    <AddForm
                        handleSubmit={handleAddSubmit}
                        defaultValues={defaultValues}
                    >
                        <AddFormAction
                            isLoading={isLoading}
                            handleCancelClick={() => setShowAddForm(false)}
                        />
                    </AddForm>
                </Box>
            )}
            <Box
                sx={{
                    border: "1px solid",
                    borderColor: "gray.200",
                    p: 3,
                    borderRadius: "12px",
                    mt: 2
                }}
            >
                {relatedPartyDetails.length > 0 ? (
                    <>
                        {relatedPartyDetails.map((party) => (
                            <RelatedPartyDetails
                                key={party.id}
                                {...party}
                            />
                        ))}
                    </>
                ) : (
                    <Typography variant="h6" textAlign="center">
                        No party records
                    </Typography>
                )}
            </Box>
            <MainPageAction partySaved={partySaved} />
        </RelatedPartyWrapper>
    )
}

const MainPageAction = ({ partySaved }) => {
    const dispatch = useDispatch()

    const handleBackBtn = () => {
        dispatch(goBack())
    }
    const handleNext = () => {
        dispatch(goToNextRelatedParty())
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <Button
                variant="outlined"
                onClick={handleBackBtn}
                sx={{ mr: 1 }}
                startIcon={NavigateBefore}
            >
                Back
            </Button>
            <Tooltip
                title={
                    partySaved
                        ? null
                        : "To proceed further, you must add atleast one related party"
                }
            >
                <span>
                    <Button
                        disabled={!partySaved}
                        variant="contained"
                        endIcon={NavigateNext}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </span>
            </Tooltip>
        </Box>
    )
}

const AddFormAction = ({ isLoading, handleCancelClick }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 1,
                justifyContent: "flex-end",
                alignItems: "center"
            }}
        >
            <Box>
                <Button
                    variant="outlined"
                    type="reset"
                    sx={{ mr: 1 }}
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={isLoading}>
                    {isLoading ? "Saving..." : "Add"}
                </Button>
            </Box>
        </Box>
    )
}
