import { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import MenuIcon from "@mui/icons-material/Menu"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Tooltip,
    Box
} from "@mui/material"
import GiaSidebar from "./SidebarLayout"
import { useLocation, useNavigate } from "react-router-dom"
import { useResponsiveMode } from "hooks/useResponsiveMode"

const drawerWidth = 340

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme }) => ({
        flexGrow: 1,

        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: `-${drawerWidth}px`,
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    transition: theme.transitions.create("margin", {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen
                    }),
                    marginLeft: 0
                }
            }
        ]
    })
)

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: `${drawerWidth}px`,
                transition: theme.transitions.create(["margin", "width"], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                })
            }
        }
    ]
}))

const GaiaPageLayout = ({ children }) => {
    const [open, setOpen] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    const [isHistoryPage, setIsHistoryPage] = useState(
        () => location.pathname?.split("/")?.at(2) === "history"
    )
    const { mode } = useResponsiveMode()

    useEffect(() => {
        setIsHistoryPage(location.pathname?.split("/")?.at(2) === "history")
    }, [location.pathname])

    useEffect(() => {
        if (mode === "MD") {
            setOpen(false)
        }
    }, [mode])

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box
            sx={{
                display: "flex",
                color: "gray.600",
                backgroundColor: "#fff",
                height: "100vh"
            }}
        >
            <AppBar
                elevation={0}
                position="fixed"
                open={open}
                sx={{ backgroundColor: "#fff" }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={[
                            {
                                mr: 2,
                                color: "#1e1e1e"
                            },
                            open && { display: "none" }
                        ]}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        {isHistoryPage && (
                            <Tooltip title="Goto gaia chat">
                                <IconButton onClick={() => navigate(-1)}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Typography
                            variant="h3"
                            noWrap
                            component="div"
                            sx={{
                                fontFamily: "The seasons",
                                color: "gray.600",
                                fontWeight: 600
                            }}
                        >
                            {isHistoryPage ? "Chat history list" : "AskGaia"}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <GiaSidebar
                handleDrawerClose={handleDrawerClose}
                mobileOpen={open}
                drawerWidth={drawerWidth}
            />

            <Main open={open}>{children}</Main>
        </Box>
    )
}

export default GaiaPageLayout
